<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          flex flex-col
          w-11/12
          sm:w-5/6
          lg:w-1/2
          max-w-2xl
          mx-auto
          rounded-lg
          border border-lighGrey
          shadow-xl
          z-20
        "
      >
        <!-- Modal Header -->
        <div
          class="
            flex flex-row
            justify-between
            p-5
            bg-lightGrey
            border-b border-lightGrey
            rounded-tl-lg rounded-tr-lg
          "
        >
          <p class="font-bold text-teal text-2xl">Message</p>
          <span class="material-icons cursor-pointer" @click="closeModal">
            close
          </span>
        </div>
        <!-- Modal Body -->
        <div class="flex flex-col px-6 py-5 bg-white">
          <!-- Title / Subject -->
          <div class="flex sm:flex-row items-center mb-1">
            <div class="w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="title"
                >Title</label
              >
              <input
                type="text"
                name="title"
                placeholder="Title"
                required
                v-model="title"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="flex-col w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="subject"
                >Subject</label
              >
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                required
                v-model="subject"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>

          <!-- Attachment -->
          <div class="flex sm:flex-row items-center mb-1">
            <div class="w-11.5/12 pb-2 flex-row">
              <label class="font-semibold text-grey flex pb-1" for="attachment"
                >Attachment</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="file"
                  name="attachment"
                  class="relative text-right opacity-0 z-20 w-full h-10"
                  @change="uploadFile"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="rounded-lg border bg-lightGrey pl-2 w-full h-10"
                    :class="[fileSizeOK ? 'border-red' : 'border-darkGrey']"
                    :placeholder="
                      !attachment
                        ? 'Click here to attach a file (< 1MB)...'
                        : attachment.name
                    "
                  />
                </div>
              </div>
              <span v-if="fileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
          </div>
          <!-- Categories -->
          <div class="flex-col items-center my-2">
            <label class="font-semibold text-grey">Category / Tags</label>
            <br />
            <div class="flex justify-between w-full py-2">
              <div class="flex items-center">
                <input
                  type="radio"
                  id="issue"
                  name="category"
                  value="issue"
                  class="h-5 w-5"
                  v-model="category"
                  checked
                />
                <label class="px-2 cursor-pointer text-lg text-grey" for="issue"
                  >Issue</label
                >
              </div>

              <div class="flex items-center">
                <input
                  type="radio"
                  id="request"
                  name="category"
                  value="request"
                  class="h-5 w-5"
                  v-model="category"
                />
                <label
                  class="px-2 cursor-pointer text-lg text-grey"
                  for="request"
                  >Request</label
                >
              </div>

              <div class="flex items-center">
                <input
                  type="radio"
                  id="suggestion"
                  name="category"
                  value="suggestion"
                  class="h-5 w-5"
                  v-model="category"
                />
                <label
                  class="px-2 cursor-pointer text-lg text-grey"
                  for="suggestion"
                  >Suggestion</label
                >
              </div>
              <div class="flex items-center pr-6">
                <input
                  type="radio"
                  id="other"
                  name="category"
                  value="other"
                  class="h-5 w-5"
                  v-model="category"
                />
                <label class="px-2 cursor-pointer text-lg text-grey" for="other"
                  >Other</label
                >
              </div>
            </div>
          </div>
          <!-- Message -->
          <label class="mb-1 font-semibold text-grey" for="Message"
            >Message</label
          >
          <div
            class="sm:flex-col bg-white border h-full w-11.5/12"
            :class="[messageEmpty ? 'border-red' : '']"
          >
            <QuillEditor
              style="min-height: 150px"
              toolbar="essential"
              theme="snow"
              contentType="html"
              v-model:content="message"
            />
          </div>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <base-button :animateButton="animateButton"> Post </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close-modal", "submit-modal"],
  components: { QuillEditor },
  data() {
    return {
      title: "",
      subject: "",
      message: "",
      category: "issue",
      practice: JSON.parse(localStorage.getItem("practice")),
      attachment: null,
      is_public: 1,
      fileSizeOK: false,
      messageEmpty: false,
    };
  },
  methods: {
    uploadFile() {
      this.attachment = this.$refs.file.files[0];
      if (this.attachment.size > 1024 * 1024) {
        this.attachment = null;
        return (this.fileSizeOK = true);
      } else {
        return;
      }
    },
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("subject", this.subject);
      if (this.attachment) {
        formData.append("attachments[]", this.attachment);
      }
      formData.append("category", this.category);
      formData.append("message", this.message);
      formData.append("practice", this.practice);
      formData.append("is_public", this.is_public);
      formData.append("type", "communication_book");
      if (!this.message) {
        this.messageEmpty = true;
      } else {
        this.$emit("submit-modal", formData);
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] + label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
</style>
