<template>
  <!-- Content -->

  <div class="flex w-11/12 items-center border-b-2 rounded-sm py-2 m-auto px-3">
    <!-- Left Side -->
    <div class=" w-1/2 sm:w-5/12 md:w-4/12 my-auto h-auto">
      <div class="flex md:mt-2 mt-6">
        <!-- Icon -->
        <div class="hidden sm:flex flex-wrap content-center ml-2 mr-2">
          <div class="rounded-full h-16 w-16  flex items-center justify-center">
            <!-- <img
                    v-if="!imageError"
                    alt="Profile"
                    @error="setAltImg"
                    :src="profileData.profile.profile_image"
                    class="mx-auto object-cover rounded-full w-full h-full "
                  /> -->
            <img
              alt="Profile"
              src="../../../assets/1.png"
              class="mx-auto object-cover rounded-full w-full h-full "
            />
          </div>
        </div>
        <!-- Thread Title + Post Info -->
        <div class="flex-col flex-wrap content-center my-2 sm:my-0">
          <div class="flex">
            <router-link
              :to="{
                name: 'cs-communicationBookItem',
                params: { threadId: postData.id },
              }"
            >
              <p>
                <strong>{{ postData.title }}</strong>
              </p></router-link
            >
          </div>

          <div class="flex">
            <p>
              <span class="hidden text-darkGrey text-sm  sm:block"
                >{{ postData.user.email }} -
                {{ convertedTime(postData.created_at) }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Tags -->
      <div class="hidden md:flex ml-2 mt-2 mr-2">
        <div class="flex w-full">
          <p
            class=" flex text-sm  justify-center items-center px-2 py-0.1 rounded-full text-white bg-darkBlue hover:bg-lightBlue"
          >
            {{ postData.category.toUpperCase() }}
          </p>
          <span
            class="w-full flex-row items-center px-1 py-0.1 rounded-full text-darkGrey"
          >
            <span
              v-for="(attachment, index) in postData.post_attachments"
              :key="attachment.id"
              ><a :href="attachment.url" target="_blank"
                ><span class="material-icons text-sm"> description </span>File{{
                  index + 1
                }}</a
              ></span
            >
          </span>
        </div>
      </div>
    </div>
    <div class=" border-r-2 h-20"></div>
    <!-- Read/Views -->
    <div class="flex flex-wrap content-center w-2/12">
      <div class="flex-row m-auto">
        <!-- Replies -->
        <div class="py-1">
          <p class="text-DarkGrey">Replies: {{ postData.answers_count }}</p>
        </div>
        <!-- Views -->
        <div class="py-1">
          <p class="text-DarkGrey">Views: {{ postData.post_views_count }}</p>
        </div>
      </div>
    </div>
    <div class=" border-r-2 h-20"></div>
    <!-- Thread Content -->
    <div
      class="hidden w-5/12 md:flex flex-wrap content-center overflow-scroll h-28 m-auto pt-2"
    >
      <div class="mx-2 pt-1 h-full">
        <p class="text-darkGrey">
          <span v-html="postData.message"></span>
        </p>
      </div>
    </div>
    <div class="pl-1 border-r-2 h-20"></div>
    <!-- Right Side -->
    <div class="flex justify-end w-1/2 sm:w-5/12 md:w-4/12 text-right">
      <!-- Anwser Time + User Info -->
      <div class="flex flex-wrap content-center ml-2">
        <div class="justify-end">
          <div class="">
            <p v-if="postData.answers.length > 0">
              {{
                convertedTime(
                  postData.answers[postData.answers.length - 1].created_at
                )
              }}
            </p>
            <p v-else>
              {{ convertedTime(postData.created_at) }}
            </p>
          </div>
          <div class="">
            <p v-if="postData.answers.length > 0">
              <span class="text-darkGrey text-sm text">{{
                postData.answers[postData.answers.length - 1].user.email
              }}</span>
            </p>
            <p v-else>
              <span class="text-darkGrey text-sm">{{
                postData.user.email
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- Icon -->
      <div class="hidden sm:flex flex-wrap content-center m-2">
        <div
          v-if="postData.answers.length > 0"
          class="rounded-full h-16 w-16  flex items-center justify-center"
        >
          <!-- <img
                    v-if="!imageError"
                    alt="Profile"
                    @error="setAltImg"
                    :src="profileData.profile.profile_image"
                    class="mx-auto object-cover rounded-full w-full h-full "
                  /> -->
          <img
            alt="Profile"
            src="../../../assets/1.png"
            class="mx-auto object-cover rounded-full w-full h-full "
          />
        </div>
        <!-- No responses -->
        <div
          v-else
          class="rounded-full h-16 w-16 flex items-center justify-center"
        >
          <!-- <img
                    v-if="!imageError"
                    alt="Profile"
                    @error="setAltImg"
                    :src="profileData.profile.profile_image"
                    class="mx-auto object-cover rounded-full w-full h-full "
                  /> -->
          <img
            alt="Profile"
            src="../../../assets/1.png"
            class="mx-auto object-cover rounded-full w-full h-full "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    postData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    getInitials(name) {
      let parts = name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      return initials;
    },
    reduceText(text) {
      return text
        .split(" ")
        .splice(0, 20)
        .join(" ");
    },
  },

  computed: {},
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0;
}
</style>
