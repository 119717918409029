<template>
  <base-dialog
    :show="!!error"
    :errorIcon="!!error"
    title="An error occurred"
    @close="handleError"
  >
    <p>{{ error }}</p>
  </base-dialog>
  <teleport to="body">
    <new-thread-modal
      :animateButton="animateButton"
      :class="{ hidden: hideModal }"
      @submit-modal="submitModal"
      @close-modal="toggleModal"
    ></new-thread-modal>
  </teleport>
  <!-- Header -->
  <div class="flex flex-nowrap text-teal">
    <div class="flex justify-center flex-1">
      <p class="text-center text-orange text-4xl font-bold p-6">
        Forum
      </p>
    </div>
    <div @click="toggleModal()" class="flex items-center justify-center w-16">
      <span class="material-icons text-3xl cursor-pointer">
        add_circle_outline
      </span>
    </div>
  </div>
  <template v-if="!isLoading">
    <forum-item
      v-for="post in getPosts.data"
      :key="post.id"
      :postData="post"
    ></forum-item>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>
  <br />
  <my-pagination
    v-if="!!getPosts.total"
    :options="options"
    v-model="page"
    :records="getPosts.total"
    :per-page="getPosts.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else />
</template>
<script>
import { markRaw } from "vue";
import newThreadModal from "@/components/modal/newThreadModal.vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import forumItem from "@/components/layout/CS/forumItem.vue";
export default {
  components: { forumItem, newThreadModal, blankPaginate },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      page: 1,
      signatureModalShow: false,
      animateButton: false,
      hideModal: true,
      error: null,
      isLoading: false,
    };
  },
  created() {
    this.loadPosts(this.page);
  },
  methods: {
    myCallback(e) {
      this.loadPosts(e);
    },
    async loadPosts(page) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("cleaner/loadPosts", page);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    toggleModal() {
      this.hideModal = !this.hideModal;
    },
    async submitModal(actionPayload) {
      this.animateButton = true;
      try {
        await this.$store.dispatch("cleaner/newThread", actionPayload);
        this.$router.push({
          path: "/cs/communicationBook/" + this.getPost.id,
          query: { new: "true" },
        });
      } catch (error) {
        this.animateButton = false;
        this.$toast.error("Oops something went wrong");
      }
    },
    handleError() {
      this.error = null;
    },
  },
  computed: {
    getPosts() {
      return this.$store.getters["cleaner/getPosts"];
    },
    getPost() {
      return this.$store.getters["cleaner/getPost"];
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  color: #425563 !important;
  font-weight: 700;
}
</style>
